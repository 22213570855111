import { useEffect, useState } from "react";
import { fetchHtmlFromBlob } from "../Utils/fetchHtmlFromBlob";

import { convertRelativeUrls } from "../Utils/parseHTHMLRelativePaths";
import { useLocation } from "react-router-dom";

export const useLoadExternalHtml = () => {
  const [html, setHtml] = useState("");
  const { pathname } = useLocation();

  useEffect(() => {
    const staticHtmlContentId = pathname.split("/")[2];

    const getPublicStaticHtml = async () => {
      const baseUrl = `${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_CONTAINER_NAME}/${process.env.REACT_APP_STORAGE_ROOT_FOLDER_NAME}/${staticHtmlContentId}/`;
      const HTML = await fetchHtmlFromBlob(staticHtmlContentId);
      const parsedHtml = convertRelativeUrls(HTML ?? "", baseUrl);
      setHtml(parsedHtml);
    };

    getPublicStaticHtml();
  }, [pathname]);

  return { html };
};
