import * as htmlparser2 from "htmlparser2";
import { Element } from "domhandler";
import * as url from "url";

export const convertRelativeUrls = (html: string, baseUrl: string): string => {
  const handler = new htmlparser2.DomHandler();
  const parser = new htmlparser2.Parser(handler);

  parser.write(html);
  parser.end();

  const processNodes = (nodes: Element[]): void => {
    nodes.forEach((node: Element) => {
      if (node.type === "tag") {
        if (node.name === "a" && node.attribs && node.attribs.href) {
          node.attribs.href = url.resolve(baseUrl, node.attribs.href);
        } else if (node.name === "img" && node.attribs && node.attribs.src) {
          node.attribs.src = url.resolve(baseUrl, node.attribs.src);
        } else if (node.name === "link" && node.attribs && node.attribs.href) {
          if (
            !(
              node.attribs["data-tag"] === "font" ||
              node.attribs.href.includes("fonts.googleapis.com")
            )
          ) {
            // link 태그 중, font 관련 태그들은 패싱
            node.attribs.href = url.resolve(baseUrl, node.attribs.href);
          }
        }
      }

      if (node.children) {
        processNodes(node.children as Element[]);
      }
    });
  };

  processNodes(handler.dom as Element[]);
  return htmlparser2.DomUtils.getOuterHTML(handler.dom);
};
