import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { STATIC_PAGE_URL } from "./Constants/pageUrls";
import ExternalStaticPage from "./Pages/ExternalStaticPage";
const DefaultRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path={STATIC_PAGE_URL} element={<ExternalStaticPage />} />
      </Routes>
    </Router>
  );
};
export default DefaultRouter;
