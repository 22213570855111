import {
  BlobServiceClient,
  StorageSharedKeyCredential,
} from "@azure/storage-blob";
const INDEX_HTML = "index.html";
export const fetchHtmlFromBlob = async (id: string) => {
  const blobServiceClient = new BlobServiceClient(
    process.env.REACT_APP_STORAGE_URL ?? "",
    (process.env.REACT_APP_STORAGE_CONNECTION_STRING ??
      "") as unknown as StorageSharedKeyCredential
  );
  const containerClient = blobServiceClient.getContainerClient(
    process.env.REACT_APP_STORAGE_CONTAINER_NAME ?? ""
  );
  const blobClient = containerClient.getBlobClient(
    `${process.env.REACT_APP_STORAGE_ROOT_FOLDER_NAME}/${id}/${INDEX_HTML}`
  );
  const downloadResponse = await blobClient.download();
  if (downloadResponse.blobBody) {
    const blobBody = await downloadResponse.blobBody;
    const textDecoder = new TextDecoder();
    const arrayBuffer = await blobBody.arrayBuffer();
    const htmlContent = textDecoder.decode(arrayBuffer);
    return htmlContent;
  }
};
